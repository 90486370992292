import React from "react"

import Hero from "../components/hero" 
import MaxWidth from "../components/maxWidth.js"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contactForm"

const ContactUs = () => (
    <>
        <Hero>
            <h1>Contact Us</h1>
        </Hero>
     <Layout>
     <SEO title="Contact Us | Corporate EAP Resources" keywords={[`EAP Contact`, `Employee Assistance`, `Employee Help`, `Boston EAP Contact`]} description="Boston area counseling and referral services for employees and their families." />
      <MaxWidth>
        <div className='container-60-40'>
          <div>
            <h2>Take the Next Step</h2>
            <p>Fill out the form here or contact us with one of the other methods below.</p>

            <p>Phone: 1 (978) 635-1712<br/>
            Email: <a href="mailto: info@eap.help">info@eap.help</a></p>
            <p><small><span style={{color: '#800000'}}><b>If you need immediate assistance and are unable to wait for a return call, please call 911 or go to your nearest Emergency Room.</b></span></small></p>
          </div>
          <form name="contact-us" netlify hidden>
            <input type="text" name="firstName"/>
            <input type="text" name="lastName"/>
            <input type="email" name="email"/>
            <input type='number' name='phone'/>
            <input type='text' name='employer'/>
            <input type='text' name='employerInfo'/>
            <textarea type='textarea'name='description'/>
          </form>

          <div>
            <ContactForm/>
          </div>
          
        </div>
        
      </MaxWidth>
    </Layout>
	 </>
)

 
export default ContactUs