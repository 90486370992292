import React from "react"
import { Link } from 'gatsby'

/*import { FormErrors } from './formErrors.js'*/
import formStyles from './forms.module.css'


/*const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}*/

class ContactForm extends React.Component {
    constructor (props) {
    super(props);
        this.state = { 
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            employer: '',
            description: '',
            formErrors: {email:''},
            emailValid: false,
            formValid: false
        }
    }

    handleInputChange = (e) => {
        const name = e.target.name
        const value = e.target.value

        this.setState({[name]: value},
            () => {this.validateField(name,value) });
    }

    handleSubmit = (e) => {
        /*fetch("/contact-thank-you", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact-us", ...this.state })
          })
            .then(() => alert("Success!"))
            .catch(error => alert(error));

        e.preventDefault();*/
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let emailValid = this.state.emailValid;
    
        switch(fieldName) {
            case 'email':
                emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                fieldValidationErrors.email = emailValid ? '' : ' is valid';
                break;
            default:
                break;
        }

    this.setState({formErrors: fieldValidationErrors,
                    emailValid: emailValid
                }, this.validateForm);
    }

    validateForm() {
        this.setState({formValid: this.state.emailValid});
    }

    errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
    }

    render() {
        return (
            <form name='contact-us' method='post' netlify netlify-honeypot="bot-field" data-netlify="true" action='/contact-thank-you' /*className={formStyles.contain}*/>
              <p style={{fontSize: '12px', marginBottom: '5px'}}>* Required field</p>

              <input type='hidden' name='form-name' value='contact-us' /> 
            
              <input 
                type="text"
                name="firstName"
                placeholder='First Name *'
                value={this.state.firstName}
                onChange={this.handleInputChange}
                required
              />
            
              <input 
                type="text"
                name="lastName"
                placeholder='Last Name *'
                value={this.state.lastName}
                onChange={this.handleInputChange}
                required
              />
             
              <input 
                type="email"
                name="email"
                placeholder='Email *'
                value={this.state.email}
                onChange={this.handleInputChange}
                required    
              />
            
              <input 
                type='number'
                name='phone'
                placeholder='Phone'
                value={this.state.phone}
                onChange={this.handleInputChange}
              />

              <input 
                type='text'
                name='company'
                placeholder='Company Name (optional)'
                value={this.state.employername}
                onChange={this.handleInputChange}  
              />
              
              <textarea 
                type='textarea'
                name='description'
                placeholder='How can we help?'
                wrap='soft'
                value={this.state.description}
                onChange={this.handleInputChange}
              />
            
            <br/>
        <button type='submit' className='btn primary' disabled={!this.state.formValid}>Submit</button>

            <p style={{textAlign: 'center', fontSize: '12px'}}><Link to='/privacy' style={{borderBottom: '0px'}}>Privacy</Link>
            </p> 
          </form>
        )
    }
}
  
export default ContactForm